import { Box, Link, Image } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { PillVariant } from '../../../types'
import { TilePill as Pill } from './components/Pill'
import { FavouriteButton } from './components/FavouriteButton'

export type CardFormat = 'landscape' | 'portrait' | 'square' | 'postcard'

const StyledWrapper = styled(Box)<{ format: CardFormat }>`
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  align-content: ${({ format }) => (format === 'portrait' ? 'end' : 'center')};
  max-width: 100%;
  height: 100%;

  ${s({
    bgcolor: 'colorBackground02',
    px: { xs: 7, sm: 9, lg: 11 },
    py: { xs: 8, md: 11 },
  })};
`

const StyledCard = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto;
`

const StyledBackPage = styled(Box)<{ format: CardFormat }>`
  position: relative;
  z-index: 1;
  flex: 1;
  transform: ${({ format }) => {
    switch (format) {
      case 'portrait':
      case 'square':
        return 'rotateY(-20deg) skewY(-5deg)'
      case 'landscape':
        return 'rotateX(-10deg) skewX(5deg)'
      default:
        return 'none'
    }
  }};
  transform-origin: ${({ format }) =>
    format === 'landscape' ? 'top left' : 'center left'};

  ${s({
    bgcolor: 'colorBlack00',
    boxShadow: 2,
  })};
`

const StyledFrontPage = styled.div`
  position: relative;
  z-index: 2;
  flex: 1;
  margin-left: -100%;

  ${s({
    boxShadow: 3,
  })};
`

const StyledImage = styled(Image)`
  width: 100%;
  display: block;
`

type Props = {
  /** Card image to display */
  image: {
    src: string
    alt: string
    dimensions: { width: number; height: number }
  }
  /** Card format */
  format: CardFormat
  /** Card product link */
  href: string
  /** Callback when the card is clicked */
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  /** Pill to display on the card */
  pill?: {
    label: string
    variant: PillVariant
  }
  /** Determines the favourite toggle state props */
  favourite?: {
    /** State of the favourite toggle */
    isSelected?: boolean
    /** Callback function to change the state of the favourite toggle */
    onSelect: () => void
  }
}

export const CardProductTileVariant: FC<Props> = ({
  image,
  format,
  href,
  onClick,
  pill,
  favourite,
}) => {
  return (
    <StyledWrapper
      width={image.dimensions.width}
      height={image.dimensions.height}
      format={format}
    >
      {pill && <Pill pill={pill} />}
      <StyledCard>
        <StyledBackPage format={format} />
        <StyledFrontPage>
          <Link
            href={href}
            onClick={onClick}
            data-testid="shared-products-card-product-tile-variant"
            aria-label={image.alt}
          >
            <StyledImage src={image.src} alt={image.alt} />
          </Link>
        </StyledFrontPage>
      </StyledCard>
      {favourite && <FavouriteButton favourite={favourite} />}
    </StyledWrapper>
  )
}
