import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { HeartToggle } from '@moonpig/launchpad-components'
import { useLocaleText } from '../../ProductTile.locale'

const StyledHeartToggleWrapper = styled.div`
  ${s({
    position: 'absolute',
    zIndex: 3,
    bottom: 4,
    right: 4,
  })}
`

type Props = {
  favourite: {
    isSelected?: boolean
    onSelect: () => void
  }
}

export const FavouriteButton: FC<Props> = ({ favourite }) => {
  const localiseText = useLocaleText()
  return (
    <StyledHeartToggleWrapper>
      <HeartToggle
        isToggled={!!favourite.isSelected}
        onToggle={() => {
          favourite.onSelect()
        }}
        ariaLabel={
          favourite.isSelected
            ? `${localiseText('card_product_tile.remove_from_favourites')}`
            : `${localiseText('card_product_tile.add_to_favourites')}`
        }
      />
    </StyledHeartToggleWrapper>
  )
}
